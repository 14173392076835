<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Контент: Контентные страницы</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Статические страницы</h5>
        </div>
        <div class="d-flex flex-column justify-content-center ml-3">
          <a class="btn btn-primary" @click="toCreate">Добавить</a>
        </div>
      </div>
      <div class="card-body">
        <content-pages-table :list="list" :paginate="pagination" @handleTableChange="handleTableChange" @removeItem="removeItem" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentPagesTable from '@/components/content/pages/ContentPagesTable'

export default {
  name: 'index',
  data() {
    return {
      list: [],
      search: '',
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList(page = this.pagination.current) {
      try {
        const params = {
          page,
        }

        const pages = (await this.$services.get('admin/content_pages', { params })).data.data.values

        this.list = pages.data
        this.pagination = {
          current: pages.current_page,
          total: pages.total,
          pageSize: pages.per_page,
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },
    async removeItem(id) {
      try {
        await this.$services.delete(`admin/content_pages/${id}`)
        await this.getList()
      } catch (e) {
        console.log(e)
      }
    },
    toCreate() {
      this.$router.push({ path: '/content/pages/new' })
    },
  },
  components: { ContentPagesTable },
}
</script>

<style scoped>

</style>
